const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  const currentPath = window.location.pathname;

  if (section) {
    const navbar = document.querySelector('nav');
    const navbarHeight = navbar ? navbar.offsetHeight : 0;
    const sectionPosition = section.offsetTop - navbarHeight;

    window.scrollTo({
      top: sectionPosition,
      behavior: 'smooth',
    });
  } else if (currentPath === '/') {
   
    setTimeout(() => {
      const targetSection = document.getElementById(sectionId);
      if (targetSection) {
        const navbar = document.querySelector('nav');
        const navbarHeight = navbar ? navbar.offsetHeight : 0;
        const sectionPosition = targetSection.offsetTop - navbarHeight;

        window.scrollTo({
          top: sectionPosition,
          behavior: 'smooth',
        });
      }
    }, 100);
  } else {
    
    window.location.href = `/#${sectionId}`;
  }
};

export default scrollToSection;
