import React from 'react';

const Privacy = () => {
  return (
    <>
      <main className="mb-6 md:mb-[4.5rem] flex flex-col justify-center items-center  px-4 pb-20 pt-36 md:pt-48 md:px-16">
        <div className="xl:w-[70%]">
          <div className="min-h-screen bg-gray-100 px-2 xl:px-8 text-gray-800 font-sans">
            <div className="container mx-auto px-4 py-8">
              <h1 className=" md:px-0 mb-6 md:mb-4 text-[2rem] md:text-5xl  font-normal font-main  text-primary-200  md:leading-normal text-center">
                Privacy Policy
              </h1>
              <p className="text-center text-sm text-gray-500">
                Effective Date: 06/03/2025 | Last Updated: 06/03/2025
              </p>

              <div className="space-y-8 my-8">
                <section>
                  <h2 className="text-2xl font-semibold mb-4 font-main">
                    1. Introduction
                  </h2>
                  <p className=" leading-8">
                    Welcome to Hage Logistics Technologies ("we," "us," or
                    "our"). We are committed to protecting your privacy and
                    ensuring the security of your personal information. This
                    Privacy Policy explains how we collect, use, disclose, and
                    safeguard your information when you visit our website
                    (www.tryhage.com), use our mobile application, or interact
                    with our services (collectively, the "Services").
                  </p>
                  <p>
                    By using our Services, you agree to the terms of this
                    Privacy Policy. If you do not agree, please do not use our
                    Services.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold mb-4 font-main">
                    2. Information We Collect
                  </h2>
                  <ul className="list-disc list-inside space-y-2">
                    <li className=" leading-8">
                      Personal Information: Includes name, email address, phone
                      number, mailing address, and other identifiers you provide
                      when signing up for our Services.
                    </li>
                    <li className=" leading-8">
                      Account Information: Username, password, and preferences.
                    </li>
                    <li className=" leading-8">
                      Payment Information: If applicable, billing details,
                      credit card, or payment information.
                    </li>
                    <li className=" leading-8">
                      Usage Data: Information about your interactions with our
                      website and app, including device information, IP address,
                      browser type, and operating system.
                    </li>
                    <li className=" leading-8">
                      Location Information: If you enable location tracking, we
                      may collect geolocation data.
                    </li>
                    <li className=" leading-8">
                      Cookies and Tracking Technologies: We use cookies and
                      similar technologies to enhance user experience and
                      analyze traffic.
                    </li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold mb-4 font-main">
                    3. How We Use Your Information
                  </h2>
                  <ul className="list-disc list-inside space-y-2">
                    <li className=" leading-8">
                      Provide, operate, and maintain our Services.
                    </li>
                    <li className=" leading-8">
                      Improve user experience and customer support.
                    </li>
                    <li className=" leading-8">
                      Process payments and transactions.
                    </li>
                    <li className=" leading-8">
                      Communicate updates, promotional materials, and important
                      notices.
                    </li>
                    <li className=" leading-8">
                      Detect, prevent, and address fraud, security breaches, or
                      technical issues.
                    </li>
                    <li className=" leading-8">
                      Comply with legal obligations and enforce our terms of
                      service.
                    </li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold mb-4 font-main">
                    4. How We Share Your Information
                  </h2>
                  <p className=" leading-8">
                    We do not sell, rent, or trade your personal information.
                    However, we may share your information in the following
                    cases:
                  </p>
                  <ul className="list-disc list-inside space-y-2">
                    <li className=" leading-8">
                      With Service Providers: Third-party vendors assisting with
                      payment processing, hosting, analytics, or customer
                      support.
                    </li>
                    <li className=" leading-8">
                      For Legal Compliance: When required by law, government
                      request, or to protect our legal rights.
                    </li>
                    <li className=" leading-8">
                      Business Transfers: If we undergo a merger, acquisition,
                      or sale of assets, your information may be transferred.
                    </li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold mb-4 font-main">
                    5. Data Security
                  </h2>
                  <p>
                    We implement industry-standard security measures to protect
                    your personal information. However, no data transmission or
                    storage is completely secure. While we strive to protect
                    your information, we cannot guarantee absolute security.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold mb-4 font-main">
                    6. Your Privacy Rights
                  </h2>
                  <p>
                    Depending on your location, you may have the following
                    rights:
                  </p>
                  <ul className="list-disc list-inside space-y-2">
                    <li className=" leading-8">
                      Access and Correction: Request a copy of your data or
                      correct inaccuracies.
                    </li>
                    <li className=" leading-8">
                      Deletion: Request the deletion of your personal data.
                    </li>
                    <li className=" leading-8">
                      Opt-Out of Marketing: Unsubscribe from promotional emails.
                    </li>
                    <li className=" leading-8">
                      Data Portability: Request a copy of your data in a
                      machine-readable format.
                    </li>
                    <li className=" leading-8">
                      Withdraw Consent: Where applicable, withdraw consent to
                      data processing.
                    </li>
                  </ul>
                  <p className=" leading-8">
                    To exercise these rights, contact us at hello@tryhage.com.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold mb-4 font-main">
                    7. Third-Party Links and Services
                  </h2>
                  <p className=" leading-8">
                    Our Services may contain links to third-party websites or
                    services. We are not responsible for their privacy
                    practices, and we encourage you to review their privacy
                    policies.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold mb-4 font-main">
                    8. Children’s Privacy
                  </h2>
                  <p className=" leading-8">
                    Our Services are not intended for children under the age of
                    13 (or 16 in certain jurisdictions). We do not knowingly
                    collect personal information from children.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold mb-4 font-main">
                    9. International Data Transfers
                  </h2>
                  <p className=" leading-8">
                    If you access our Services from outside Canada, your
                    information may be transferred to and processed in Canada or
                    other jurisdictions with different data protection laws.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold mb-4 font-main">
                    10. Changes to This Privacy Policy
                  </h2>
                  <p className=" leading-8">
                    We may update this Privacy Policy from time to time. We will
                    notify you of significant changes through email or by
                    updating the effective date at the top of this page.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold mb-4 font-main">
                    11. Contact Us
                  </h2>
                  <p className=" leading-8">
                    If you have questions or concerns about this Privacy Policy,
                    please contact us at:
                  </p>
                  <p className=" leading-8">
                    Hage Logistics Technologies
                    <br />
                    Address: 18 King Street East, Suite 1400, Toronto, Ontario
                    M5C 1C4, Canada
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Privacy;